/* Bluelink Base - AdminLTE */

body {
	padding-top: 51px;
}

.sr-only {
	display: none;
}


.content-header {
	padding: 1rem 0.5rem;
}

.navbar-brand {
	height: 51px;
	background-image: url('/images/top_logo-sm.gif');
	background-repeat: no-repeat;
	min-width: 210px;
}

.navbar-brand.other {
    background-image: url("/images/top_logo_BW-sm.gif");
}

.navbar-bly {
	padding: 0 !important;
}

.footer-bluelink {
	background: #efefef !important;
	border-top: 1px solid #dee2e6;
  	color: #869099;
  	padding: 1rem;
}

.footer-bluelink dt {
	display: flex;
}

.footer-bluelink dt a {
	color: #000099;
}

.footer-bluelink dt::before {
	color: #ff6600;
	content: ">";
	padding-right: 5px;
}

.footer-bluelink dt.static::before {
	color: #99ccff;
	content: ">";
	padding-right: 5px;
}

.bluelink-wrapper {
	min-height: calc(100vh - 52px - 148px - 2em );
	margin-bottom: 2rem;
	
}


/* contact page */
#content  p.contact strong {
	width: 120px;
	float: left;
}

#content  p.contact span {
	width: 200px;
	float: left;
}

#content  p.contact a {
	width: 200px;
	color: #3399FF;
	float: left;
	text-decoration: none;
}

#content  p.contact a:hover, #content  p.contact a:active {
	color: #000099;
}

.product .info.d-block.d-sm-none {
	clear: both;
	/*padding-bottom: 8px;*/
	padding-right: 15px;
	padding-left: 34px;
	top: -20px;
}

/* Overlays with Bootstrap icon */
.modal-backdrop .bi,
.card .overlay.dark > .bi {
    color: #ced4da;
}
.card .overlay > .bi {
	color: #343a40;
}

#overlay.modal-backdrop {
	border-radius: 0;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    z-index: 50;
}

/* Overlay in catalog */
.bl-catalog .overlay {
    border-radius: 0;
    align-items: flex-start;
    background-color: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    z-index: 50;
}

.modal-backdrop i,
.bl-catalog .overlay i {
	margin-top:40vh;
}

.bl-catalog .overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.bl-catalog .card img {
	vertical-align: text-bottom;
}

/* bootstrap icon 2x */

.bi.bi-2x {
	font-size: 2em;
}

.bi.bi-4x {
	font-size: 4em;
}

.profile label.form-label,
.profile label.col-form-label {
    font-weight: normal;
}

/* NEW PRODUCT ROW */

.product-row {
	display:flex;
	justify-content: space-between;
}


/* OLD STUFF */

/* base document*/
.login, .login a, .login a:visited {
	text-align: right;
	color: #000099;
	margin: 3px;
	font-size: 12px;
	background: #ffffff
}

.showblock {
	display: block;
}

.hideblock {
	display: none;
}

#login {
	top: 111px;
	height: 20px;
	width: 760px;
	position: absolute;
	left: 20px;
	padding-top: 3px;
	background: #ffffff;
}

.logos {
	position: static;
	margin: 10px 10px 0px 5px;
	_margin: 10px 10px 0px 5px;
	width: 758px;
	/*color:#FFFFFF;*/
	background-color: #ffffff;
	float: left;
	border: 1px solid #000099;
}

a.pdflink, a.pdflink:visited, a.pdflink:active {
	background-image: url("/images/pdficon_small.png");
	background-repeat: no-repeat;
	color: #3399FF;
	font-size: 10px;
	font-weight: normal;
	line-height: 30px;
	margin-left: 10px;
	padding-bottom: 3px;
	padding-left: 20px;
	padding-top: 3px;
	text-decoration: none;
}

a.pdflink:hover {
	color: #000099;
}

a.stock span.stocktip {
	display: none !important;
}

a.stock:hover {
	position: relative;
	cursor: help;
	text-decoration: none;
	color: #000099;
}

a.stock:hover span.stocktip {
	display: block !important;
	position: absolute;
	top: 20px;
	left: -100px;
	z-index: 1000;
	/* formatting only styles */
	padding: 5px;
	margin: 10px;
	z-index: 100;
	background: #f0f0f0;
	border: 1px dotted #c0c0c0;
	opacity: 0.9;
	width: 250px;
	text-decoration: none;
	/* end formatting */
}

#login a, #login a.visited {
	text-align: right;
	color: #000099;
	font-size: 12px;
	background: #ffffff
}

input.submitgeneral {
	margin: 0px;
	padding: 1px 2px 3px 2px;
	height: 20px;
	color: #FFFFFF;
	background-color: #3399FF;
	border: none;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
}

table.docdetails {
	border-spacing: 0px;
	border-collapse: colapse;
}

table.docdetails td.header {
	color: #999999;
	/*font-size: 10px;*/
	width: 125px;
	vertical-align: top;
}

table.docdetails td.left {
	width: 325px;
	/*font-size: 11px;*/
}

table.docdetails td.right {
	width: 125px;
	/*font-size: 11px;*/
}

#largeImage {
	position: absolute;
	padding: 1px;
	background-color: #e3e3e3;
	border: 1px solid #bfbfbf;
}

/* below this line is for Responsive bootstrap site */
.navbar-bluelink .dropdown-menu .badge-notification, .badge-notification
	{
	background-color: #ffffff !important;
	color: #ff6600 !important;
	text-shadow: none;
}

.navbar-bluelink .dropdown-menu > li > a:hover .badge-notification,
.navbar-bluelink .dropdown-menu > li > a:focus .badge-notification 
	{
	background-color: #e8e8e8 !important;
}

.badge-notification-inline {
	margin-left: -25px;
}



.list-group-item.active .badge-notification {
	background-color: #000099 !important;
}

.notification-label {
	color: #ff6600 !important;
	font-weight: normal;
	text-shadow: none;
	margin-left:25px;
}

.notification-label-inline {
	color: #ff6600 !important;
	font-weight: normal;
	text-shadow: none;
}

.err .form-control, 
.err.form-control,
.form-control:invalid, 
.form-control:invalid + .select2 .select2-selection {
	border-color: #ff6600 !important;
}

.err .form-control:focus, .err.form-control:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px
		rgba(255, 120, 0, 0.6);
	outline: 0 none;
}

.form-control.sel {
	background-color: #f9f8a5 !important
}

.form-control.bl-select {
	padding: 0;
}

/*.form-group.row .form-control {
	padding: 5px 5px !important;
}*/

ul li.adv-content {
	background-color: #000099;
	color: #ffff66
}

ul.products, ul.advice {
	margin: 0px;
	padding: 0px;
}

.bl-content, .bl-content-portal {
	background-color: white;
	padding-top: 15px;
	padding-bottom: 30px;
	min-width: 360px;
	/*	margin-left: 0px;*/
}


ul.products li {
	width: 385px;
	display: inline-block;
	vertical-align: top;
}

ul.advice li {
	width: 385px;
	display: inline-block;
	vertical-align: top;
	height: 25px;
}

.uitvoering ul li {
	display: list-item;
	white-space: nowrap;
}

.logobox {
	height: 165px;
	width: 385px;
	margin: 10px 5px 0;
	background: white;
}

/*body {
	font-family: Arial, Helvetica, sans-serif;
	margin: 0;
	padding: 0;
	padding-top: 51px;
	color: #000099;
}*/

* {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}



#user-xs {
	background-color: #ffff66;
	padding: 15px 0;
	font-size: 10px;
	margin-right: 5px;
	color: #777777;
}


#bestel-xs {
	background-color: #ffff66;
	padding: 18px 10px;
	font-size: 10px;
	margin-right: 5px;
	color: #777777;
}


.shrink #bestel-xs {
	background-color: #ffff66;
	padding: 13px 10px;
	font-size: 10px;
	margin-right: 5px;
}

#user-xs a,
#bestel-xs a {
	color: #777777;
}

.searchbar-xs {
	margin: 0px;
}

.navbar-bluelink {
	background: #ffff66 !important;
	color: #009999;
	min-width: 360px;
}

.navbar-bluelink .navbar-right {
	margin-right: 0px;
}

.navbar-bluelink .navbar-collapse {
	margin-left: 0px;
}

.navbar-bluelink .navbar-brand {
	padding-right: 0px;
}

.navbar-bluelink.shrink {
	min-height: 35px;
}

.navbar-bluelink.shrink navbar>a {
	/* padding-top: 10px !important;*/
	padding-bottom: 10px !important;
	font-size: 13px;
}

.navbar-bluelink.shrink .navbar-brand {
	padding-top: 0px;
	padding-left: 0px;
	padding-bottom: 0px;
	height: 31px;
	background-position: 0 -6px;
}

.navbar-bluelink .bluelink-phone {
	color: #003366;
	float: left;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: 210px;
	margin-right: 01px;
}

.navbar-bluelink .bluelink-phone-sm {
	clear: both;
	color: #003366;
	float: left;
	margin-bottom: 2px;
	margin-top: -10px;
}

.navbar-bluelink.shrink .navbar-toggle {
	padding: 4px 5px;
	margin: 8px 15px 8px 0;
}

.navbar-bluelink.shrink .navbar-nav>li>a {
	padding-top: 10px;
	padding-bottom: 10px;
}

.navbar-bluelink.shrink  .bluelink-phone {
	margin-top: 10px;
	margin-bottom: 10px;
}

.navbar-bluelink.shrink li a.dropdown-toggle.flag {
	background-position: 20px 13px;
}

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>a:hover,
	.navbar-default .navbar-nav>li>a:focus {
	border: 1px solid #ffff66;
	padding-left: 5px;
	padding-right: 5px;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover,
	.navbar-default .navbar-nav>.open>a:focus, .nav .open>a, .nav .open>a:hover,
	.nav .open>a:focus {
	background-color: #eeee00;
	border: 1px solid rgba(0, 0, 0, 0.15);
	background-image: none;
	box-shadow:none;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus
	{
	background-image: none;
	background-color: #000099;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus
	{
	background-image: none;
	background-color: #000099;
	border: 1px solid #ddd;
	text-shadow: none;
}

.dropdown-menu>li>a, .dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus
	{
	background-image: none;
}

div.xprijs {
	color: #000099;
	padding-right: 5px;
	text-align: right;
	width: 125px;
}

div.xprijs span.vat {
	color: #3399ff;
}

@media ( max-width : 460px) {
	.container {
		padding: 0;
	}
	
	.card-body {
		padding: 0;
	}
	
	.card-body {
		padding-left: 5px;
		padding-right: 5px;
	}
	.bl-content-portal, .bl-content {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	
	.option-list {
		margin-left: 0 !important;
	}
	ul.lijst li.level2 .description {
		/*float: none !important;*/
		width: 180px!important;
	}
	.product {
		width: 100% !important;
	}
	.well {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.panel-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	div.well.bl-document {
		margin: 0;
	}
	
	.portal-prodlist div.level3 {
		margin-left: 15px !important;
	}
	div.level3 {
		margin-left: 5px !important;
	}
	
	div.level2 {
		margin-left: 0px !important;
	}
	
	.product-row {
		flex-wrap: wrap;
	}
	
	.product-row .item {
		order: 1;
		flex: 0 0 calc(14ch + 64px);
	}
	.product-row .item .prodimg {
		width: 64px;
		height: 64px;
	}
	
	.product-row .bestel {
		order: 2;
		flex: 0 0 calc(11ch + 85px);
	}
	
	.product-row .bestel span button {
		float:right;
	}
	
	.product-row .break {
		flex-basis: 100%;
		height:1px;
	}
	
	.product-row .info {
		flex-grow: 2;
		order: 3;
		padding-right: 5px;
		padding-left: 5px;
		margin-bottom: 10px;
		top: -20px;
	}
	
	.row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.form-horizontal .form-group {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.prod-info h1 {
		font-size: 14px;
		font-weight: bold;
	}
	
	div.hilijst .description,
	div.hilijst {
		padding-left: 0 !important;
	
	}
	
	.profile label.form-label {
		text-align: left; 
	}	
	
	
}
@media ( max-width : 460px) {
	.flex-direction-nav a {
		display:none !important;
	}
	
	#navbarCollapse {
		order: 20
	}
	
	.prod-info h1 {
	font-size: 14px;
	}
	
	div.filters {
		border: 1px solid lightgray;
		margin-bottom: 5px;
		padding-right: 5px;
	}
	
	.bluelink-form .table td,
	.bluelink-form .table th {
		padding: .6rem .3rem;
	}
}

@media ( max-width: 400px) {
	.product {
		font-size: 12px !important;
	}
	
	.product .bestel {
		padding: 0;
	}
}

@media ( max-width: 320px) {
	.product {
		font-size: 10px !important;
	}
	
	.product .bestel {
		padding: 0;
	}
	

	.portal-prodlist div.level3 {
    	margin-left: 0px !important;
	}
}

@media ( min-width : 460px) and ( max-width: 991px) {
	body>.bl-content-portal {
		width: 415px
	}
	
	.navbar .bestel {
		order: 5
	}
	
	#navbarCollapse {
		order: 20
	}
	
	div.well.bl-document {
		margin: 17px;
	}
	.prod-info h1 {
	font-size: 18px;
	}
	
	div.hilijst {
		padding-left: 15px;
	
	}
	
	.product-row .item {
		order: 1;
		flex: 0 0 calc(14ch + 64px);
	}
	
	.product-row .item .prodimg {
		width: 64px;
		height: 64px;
	}
	
	.product-row .bestel {
		order: 2;
		flex: 0 0 180px;
	}
	
	.product-row .break {
		flex-basis: 100%;
		height:1px;
	}
	
	.product-row .info {
		flex-grow: 2;
		order: 3;
		padding-right: 5px;
		padding-left: 5px;
		margin-bottom: 10px;
		top: -20px;
	}
	
	.product-row {
		flex-wrap: wrap;
	}
	
	.portal-prodlist{
		padding-top:25px;
		clear:both;
		margin-left: -15px;
		margin-right:20px;
	}
}

@media ( min-width : 992px) {
	body>.container {
		width: 810px
	}
	
	.navbar .bestel {
		order: 4
	}
	
	.portal-prodlist{
		padding-top:25px;
		clear:both;
		margin-left: -15px;
		margin-right:20px;
	}
	
	.prod-info h1 {
	font-size: 24px;
	}
	
	.portal-prodlist .product .info a {
	font-size: 1.2em;
	}
	.product-row .item {
	order: 1;
	flex: 0 0 calc(14ch + 96px);
	}
	
	.product-row .item .prodimg {
		width: 72px;
		height: 72px;
	}
	
	.product-row .bestel {
	order: 3;
	flex: 0 0 calc(110px + 11ch);
	}
	.product-row .info {
	flex-grow: 2;
	order: 2;
	}
	
}


@media ( min-width : 576px) {
	.profile label.control-label {
		text-align: right; 
		margin-bottom:0;
	}	
	
}

@media ( min-width : 1200px) {
	body>.container {
		width: 1200px;
	}
	
	.navbar .bestel {
		order: 7
	}
	
}

/*@media ( min-width : 768px) {
	body {
		padding-top: 64px;
	}
}

@media ( min-width : 992px) {
	body {
		
		padding-top: 51px;
	}
}*/

table.prijslijst .totaalprijs {
	font-weight: bold;
	text-align: right;
	vertical-align: top;
	white-space: nowrap;
}

table.prijslijst thead tr td {
	color: #999999;
	font-weight: normal;
}

table.prijslijst {
	/*font-size: 11px;*/
	color: #000099;
}

table.prijslijst td.bestel a {
	background-image: url("/images/n_verwijder_new.gif");
	background-repeat: no-repeat;
	clear: none;
	float: left;
	height: 13px;
	overflow: hidden;
	vertical-align: top;
	width: 13px;
}

table.prijslijst td.prijs {
	text-align: right;
}

table.prijslijst tr.prijs td {
	border-top: 1px solid #000099;
}

table.prijslijst tr.prijs td.prijs {
	font-weight: bold;
}

table.prijslijst tr.totaal td.prijs {
	background-color: #ffff66;
	vertical-align: middle;
	font-weight: bold;
}

table.prijslijst tr.totaal td.hidden-xs {
	background-color: #ffff66;
}

table.prijslijst tr td.prodinfo, table.prijslijst tr td.prijs {
	width: 100%;
}

table.prijslijst tr td.prodinfo span {
	color: #3399ff;
}

table.prijslijst tr td.prodinfo ul {
	color: #3399ff;
	padding:0px;
	margin-bottom: 0;
}

table.prijslijst tr td.prodinfo ul li {
	color: #3399ff;
	list-style-type:circle;
	list-style-position:inside;
}

table.quoteinfo {
	font-size: 11px;
	color: #000099;
	margin-bottom: 0px;
}

table.quoteinfo td {
	border: 0 !important;
}

table.quoteinfo td.title {
	color: #999999;
}

.btn-bluelink {
	background-color: #3399ff;
	color: #ffffff !important;
	border-radius: 0px;
	margin-right: 5px;
	margin-bottom: 5px;
}

.btn-bluelink:focus, .btn-bluelink:hover {
	background-color: #000099!important;
	color: #ffffff;
}

.btn-light {
	background-color: #eee;
	color: #000099 !important;
	border-radius: 0px;
	margin-right: 5px;
	margin-bottom: 5px;
}

.btn-light:focus, .btn-light:hover {
	color: #3399ff!important;
}

.btn {
	--bs-button-box-shadow: 0px;
}

.btn-bldefault {
	--bs-btn-border-color: #dddddd;
	--bs-btn-hover-border-color: #dddddd;
	--bs-btn-hover-bg: #e9ecef;
	--bs-btn-disabled-border-color: #dddddd;
	--bs-btn-disabled-color: #444444;
}

.input-group-btn .btn-light {
	margin: 0px;
	border-color: #ccc;
}

.btn-bluelink.list-group-item{
	margin: 0px;
}

.panel-bluelink {
	border-color: #fff;
	border-radius: 0px !important;
	color: 000099;
}

.fixed-bluelink {
	max-width: 780px;
}

.panel-bluelink>.panel-heading {
	background-color: #000099;
	border-color: #fff;
	color: #fff;
	font-size: 14px;
}

/*.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2,
	.col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
	.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5,
	.col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
	.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8,
	.col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
	.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11,
	.col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12
	{
	padding-left: 5px !important;
	padding-right: 5px !important;
}*/

/* catalog and document lists */
a.level1, div.level1 {
	background-image: url("/images/cat_driehoekbl_sel.gif");
	background-position: 0 2px;
	background-repeat: no-repeat;
	padding-left: 17px;
	font-weight: bold;
	font-size: 12px;
	color: #000099;
	display: block;
}

a.level1.collapsed, div.level2.collapsed {
	background-image: url("/images/cat_driehoekbl.gif");
}

a.level2, div.level2 {
	background-image: url("/images/cat_driehoekgr_sel.gif");
	background-position: 0 2px;
	background-repeat: no-repeat;
	padding-left: 17px;
	/*margin-left: 17px;*/
	font-size: 12px;
	font-weight: bold;
	color: #000099;
	display: block;
	clear: both;
}

a.level2.collapsed, div.level2.collapsed,a.level2 collapsed {
	background-image: url("/images/cat_driehoekgr.gif");
}

div.level3 {
	margin-left: 17px;
}

.bl-panel-control {
	margin-right: 10px;
	padding-top: 8px;
}

.borderless tbody tr td, .borderless thead tr th {
	border: none;
}

/* navbar language selector */
.dropdown-menu li a.flag, .dropdown-menu  li a.flag:active,
	.dropdown-menu  li a.flag:hover {
	background-position: 20px 5px;
	background-repeat: no-repeat;
	padding-left: 40px;
}

li a.dropdown-toggle.flag {
	background-position: 20px 18px;
	padding-left: 30px;
	background-repeat: no-repeat !important;
}

li a.flag.nl_nl:focus, li a.flag.nl_nl:hover, li a.flag.nl_nl {
	background-image: url("/images/flag_nl-nl.png") !important;
	padding-left: 40px !important;
}

li a.flag.en_us:focus, li a.flag.en_us:hover, li a.flag.en_us {
	background-image: url("/images/flag_en-us.png") !important;
	padding-left: 40px !important;
}

/* kill double badge notifications in forms */
form span.badge.badge-notification+span.badge.badge-notification {
	display: none;
}

/* product line */
.product {
	font-size: 14px;
	clear: both;
}

.product .prodimg {
	vertical-align: top;
	border: 1px solid #dddddd;
	margin-right: 10px;
	float:left;
}

.product .bestel span > span {
	float: right;
	color: #999;
	text-decoration: line-through;
}

.product .item {
	margin-bottom: 2px;
	padding-bottom: 3px;
	padding-left: 0px;
	padding-right: 0px;
	min-height: 1px;
}

.product .item .prodno {
	font-weight: bold;
	color: #3399ff;
	display:block;
	word-break:break-all;
}


.product .info a {
	color: #000099;
}


.product .bestel {
	padding-left: 0px;
/*	padding-right: 15px;*/
}

.product .bestel .add, .product .bestel .add:visited {
	background-image: url("/images/n_voegtoe_new.gif");
	background-repeat: no-repeat;
	background-color: white;
	height: 15px;
	overflow: hidden;
	text-indent: 80px;
	width: 65px;
	background-position: 4px 2px;
	float: left;
	border: none;
}

.product .bestel .add.bestelsel, .product .bestel .add.bestelsel:visited {
	background-image: url("/images/n_toegevoegd_uitbr.gif");
}

.product .bestel .add:active, .product .bestel .add:hover {
	background-position: 4px -18px;
}

.product-head .bestel .add.bestelsel, .product-head .bestel .add.bestelsel:hover
	{
	background-image: url("/images/n_toegevoegd_infoprod.gif");
	background-position: 0 0;
	background-repeat: no-repeat;
}

.product .bestel .service {
	font-size: 13px;
	color: #47D650;
	float:left;
} 

.boxnew .service
{
	color: #47D650;
}

.product .bestel span.prijs {
	color: #000099;
	display: inline;
	margin: 0;
	text-align: right;
	text-decoration: none;
	font-weight: bold;
	width: calc(11ch + 18px);
}

.product .bestel span.prijs .volprice {
	float: left;
	padding-left: 5px;
	padding-top:3px;
}

.product.aanbieding .bestel span.prijs {
	background-image: url("/images/cat_uitroepteken.gif");
	background-position: -2px 3px;
	background-repeat: no-repeat;
	padding-left: 11px;
}

.product.aanbieding .infopromo {
	color: #cc5500;
    font-size: 10px;
    font-weight: bold;
    text-decoration: none
}

.prod-info span.description {
	font-size: 12px;
	/*font-weight: bold;*/
}

.prod-info ul.stock
{
	padding-left:20px;
}

.visible-xs ul.stock {
	line-height: 1.5;
}

.prod-info .visible-xs {
	margin-top: 10px
	/*line-height: 2;*/
}

.product-head.visible-xs .bestel {
	padding:0;
	/*line-height: 2*/
}
/* font-size in media query */
.prod-info h1 {
	margin: 0 0 5px 0;
	/*font-weight: bold;*/
	color: #000099
}

.prijslijst span.service,
.prod-info span.service 
{
	font-size: 13px;
	color: #47D650;
}

a:hover, a:active {
	text-decoration: none !important;
}

/* Product Page */
.product-head {
	font-size: 11px;
}

.product-head .image {
	border: 1px solid #999999;
	margin: 0;
	padding: 0;
	/*height: 98px;
	width: 98px;*/
}

.product-head .image a.lightbox {
	cursor: zoom-in;
}

.product-head .prod-info .vergroot,
.product-head .vergroot {
	background-image: url("/images/inf_vergroot_new.gif");
	background-repeat: no-repeat;
	width: 63px;
	color: transparent;
	float: left;
}

.product-head .vergroot {
	margin-top:5px;
	}
	

.product-head .prod-info .info {
	background-image: url("/images/inf_extrainfo_new.gif");
	background-repeat: no-repeat;
	float: left;
	width: 66px;
	color: transparent;
}

.product-head .prod-info .ddd {
	background-image: url("/images/inf_3dtour_new.gif");
	background-repeat: no-repeat;
	float: left;
	width: 47px;
	color: transparent;
}

.product-head .prod-info img {
	/*float: left;*/
	margin: 0 5px 0 0;
	vertical-align: baseline;
}

.product-head .prod-info.visible-xs {
	clear: both;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 2px;
}

.product-head .bestel {
	/*background-color: #ffff66;
	height: 98px;*/
	padding: 10px;
	color: #000099;
}


.prod-info.stock  strong {
	/*display: block;*/
	font-size: 16px;
	padding-top: 10px;
}


.option-list {
	margin-left: -17px;
}


ul.lijst li.level1, div.level1 {
	background-image: url("/images/cat_driehoekbl_sel.gif");
	background-position: 0 2px;
	background-repeat: no-repeat;
	padding-left: 17px;
	font-weight: bold;
	font-size: 12px;
	color: #000099;
	list-style-type: none;
}

a.level1.collapsed, div.level2.collapsed {
	background-image: url("/images/cat_driehoekbl.gif");
}
div.hilijst-group {
		padding-top: 5px;
}




.hilijst .value {
	color: #3399ff;
	font-size:12px
}

.hilijst .description {
	font-size:11px
}

.hilijst .value.col-lg-10 {
	padding-left:10px!important;
}

.row.product-head {
	margin-left:-5px;
	margin-right:0px;
}

.row.product-head:not(:first-child) {
	margin-top:5px;
}


ul.lijst {
	padding-left: 0px;
}

ul.lijst li.level2 {
	font-size: 11px;
	border-bottom: 1px dashed #999999;
	color: #000099;
	list-style-type: none;
}

ul.lijst li.level2 .description {
	display: table-cell;
	font-weight: normal;
	padding: 0 10px 0 20px;
	width: 350px;
}

ul.lijst li.level2 .advice {
	display: block;
	font-weight: normal;
	padding: 0 10px 0 20px;
}

ul.lijst li.level2 .value {
	display: table-cell;
	font-weight: bold;
	padding: 0;
}



.boxselect{
	border: 3px solid #fff;
	height: 170px;
}
.boxselect.top,
.nav-bluelink-top .btn-blinfo,
.nav-bluelink-top .badge
 {
	background-color: #ff8c40;
}

.nav-bluelink-sub .btn-blinfo,
.nav-bluelink-sub .badge,
.boxselect.sub {
	background-color: #ffff66;
}

.nav-bluelink-notes .btn-blinfo,
.nav-bluelink-notes .badge,
.boxselect.notes {
	background-color: #99ccff;
}

.nav-bluelink-top > .btn,
.nav-bluelink-sub > .btn,
.nav-bluelink-notes > .btn,
.nav-bluelink-for > .btn {
	color: #000099;
	white-space:normal !important;
    word-wrap:break-word;
    text-align:left;
    /*width:100%*/
}

.nav-bluelink-top:hover ,
.nav-bluelink-top:hover .btn,
.nav-bluelink-top:hover .badge,
.nav-bluelink-notes:hover ,
.nav-bluelink-notes:hover  .btn,
.nav-bluelink-notes:hover  .badge,
.nav-bluelink-sub:hover,
.nav-bluelink-sub:hover .btn,
.nav-bluelink-sub:hover .badge,
.nav-bluelink-for:hover > .btn {
	background-color: #eee;
}

.nav-bluelink-top,
.nav-bluelink-sub,
.nav-bluelink-for,
.nav-bluelink-notes {
	border: 1px solid #ddd;
	margin: 5px;
	color: #000099;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}

.nav-bluelink-top >.btn-blinfo,
.nav-bluelink-sub >.btn-blinfo,
.nav-bluelink-notes >.btn-blinfo,
.nav-bluelink-for >.btn-blinfo {
	padding: 6px;
	padding-top: 5px;
	padding-bottom: 7px;
	padding-left: 0px;
	color: #888;
}

.nav-bluelink-top,
.nav-bluelink-top > .btn {
	background-color: #ff8c40;
}

.nav-bluelink-sub,
.nav-bluelink-sub > .btn {
	background-color: #ffff66;
}	

.nav-bluelink-notes,
.nav-bluelink-notes > .btn {
	background-color: #99ccff;
}

.nav-bluelink-for,
.nav-bluelink-for > .btn {
	background-color: #cccccc;
}


.nav > li  >a.nav-bluelink-hint {
	padding-bottom: 0;
    padding-left: 3px;
	color: #000099;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
.nav > li  >a.nav-bluelink-hint:focus,
.nav > li  >a.nav-bluelink-hint:hover {
		background: #ffffff;
	}
.list-group-item.bl-search {
	padding:0px;
}

.list-group-item.bl-cat-type {
	background-color: #ff8c40;
	}
	
.list-group-item.bl-cat-for a,	
.list-group-item.bl-cat-type a,
.list-group-item.bl-cat-serie a,
.list-group-item.bl-cat-notes a {
	color: #000099;
}
	
.list-group-item.bl-cat-serie {
	background-color: #ffff66;
	}
	
.list-group-item.bl-cat-notes {
	background-color: #99ccff;
	}		
	
.list-group-item.bl-cat-for {
	background-color: #cccccc;
	}		
	
/*.btn.btn-portal {
	 font-size: 12px;
    padding: 5px 10px;
     background-color: #fff;
    border-color: #ddd;
    color: #777;
}

.btn.btn-portal-active {
	 font-size: 12px;
    padding: 5px 10px;
    background-color: #3399ff;
    border-color: #ddd;
    color: #fff;
    cursor: default;
}		
*/

.boxsearch {
	background-color: #ffFF66;
	border: 3px solid #fff;
	
}


.boxnew {
	font-size: 11px;
	background-color: #cccccc;
	padding: 5px;
	border: 3px solid #fff;
	border-right: 6px solid #fff;
	border-left: 0px;
	min-height: 170px;

}

.boxnew h3 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
    color: #000099;
    overflow: hidden;
    white-space: nowrap;
}

.boxnew .uitvoering {
	min-height: 150px;
}

.boxnew .uitvoering ul {
    list-style-position: inside;
    padding-left: 0px;
    overflow:hidden;
    margin-bottom: 5px;
    color: #000099;
    max-height: 94px;
}

.boxnew .uitvoering ul li.cond {
    list-style: outside none none;
}

.boxnew .uitvoering ul.info {
	
	 list-style: outside none none;
	 position:absolute;
	 bottom: 0px;
}

.boxnew .uitvoering a.moreinfo {
    background-image: url("/images/hm_meerinfo_new.gif");
    width: 62px;
}

.boxnew .uitvoering a.bestel,
.boxnew .uitvoering button.bestel {
    background-image: url("/images/n_h_voegtoe_new.gif");
    width: 60px;
}

.boxnew .uitvoering a.bestelsel,
.boxnew .uitvoering button.bestelsel {
    background-image: url("/images/n_toegevoegd_infoprod.gif");
    width: 60px;
}

.boxnew .uitvoering .partno {
    margin: 3px 5px 5px 0;
    display: block;
    float:left;
    font-weight: bold;
    color: #000099;
    line-height: 14px;
}


.boxnew .uitvoering a.bestel,
.boxnew .uitvoering a.bestelsel,
.boxnew .uitvoering button.bestel,
.boxnew .uitvoering button.bestelsel {
    background-repeat: no-repeat;
    background-color: transparent;
    display: inline-block;
    height: 14px;
    margin: 3px 5px 5px 0;
    overflow: hidden;
    padding: 0;
    text-indent: 60pt;
    border: none;
}

.boxnew .uitvoering button.bestel:hover {
	background-position: 0px -20px;
}
.boxnew .uitvoering a.moreinfo {
    background-repeat: no-repeat;
    display: block;
    float:left;
    height: 13px;
    margin: 2px 5px 0 0;
    overflow: hidden;
    padding: 0;
    text-indent: 60pt;
}

.boxnew li.aanbieding {
    margin: 0;
    overflow: hidden;
   
}
.boxnew .prijs .promo {
	color: #cc5500;
	font-weight: bold;
	font-size: 10px;
	text-decoration: none
}

.boxnew .aanbieding img {
    
    margin: 0 5px 0 0;
}

.boxnew .prijs {
	color: #666666;
    float: left;
    min-height: 150px;
}
.boxnew .prijs em  {
	color: #000099;
  /*  float: left;*/
}

.boxnew .prijs strong {
	font-size: 16px;
	/*display:block;*/
	
}

.boxnew .prijs .priceblock {
	position: absolute;
	bottom: 0px;
}


.largecat {
float: left;
clear:both;
    font-size: 18pt;
    font-weight: bold;
    padding: 10px;
}

.catinfo {
float: left;
    clear:both;
    font-weight: bold;
    padding: 10px;
   
}





/* input-group-xs hack */
.input-group-xs>.form-control, .input-group-xs>.input-group-addon,
	.input-group-xs>.input-group-btn>.btn {
	font-size: 10px;
	height: 23px;
	line-height: 1.5;
	padding: 3px 8px;
}

/* border around inactive tabs */
.nav-tabs>li>a {
	border: 1px solid #eeeeee;
}
/* formcontrol 'glow' */
.form-control:focus {
	border-color: #3399ff;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px
		rgba(51, 153, 255, 0.6);
	outline: 0 none;
}

/** order /quote */
tr.info-line td {
	border: none !important;
	padding-top: 2px !important;
}



select option.notification,
.select2-results__option .notification {
	font-weight: bold!important;
	color: #ff6600;
}

.has-notification .form-control,
.has-notification .select2-container--default {
	border-color: #ff6600;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-notification .select2-container--default {
	border: 1px solid #ff6600;

	}

.has-notification .form-control-feedback {
	color: #ff6600;
}

.pagination > li > a {
	color: #3399ff;
}
.pagination > li.active > span {
	background-color: #3399ff;
	border-color: #3399ff;
}

dl.links dt a:hover {
	color: #3399ff;
}

.input-group-btn .btn-bluelink {
	margin: 0px;
	border-left: 1px solid #ddd;
}

.bl-content > div > ul.nav.nav-pills li a {
	margin-left:0px;
}

ul.nav-tabs.nav-bluelink li.opt a {
	color: #000099;
}

ul.nav-tabs.nav-bluelink li.opt a {
	color: #555;
}

ul.nav-tabs.nav-bluelink li.active a {
	color: #009;
}

.list-group-item.seperator {
	padding: 3px;
}

.doc-line span strong {
	/*padding-left:10px;*/
	color: #009;
}

.doc-line span.status strong {
	color: #39f;
}

.doc-line span strong.notification-label {
	font-weight: 700

	}
.doc-line span {
	font-weight: normal;
	color: #777;
}
.doc-line span.status {
	padding-left:5px;
}

.doc-line {
	margin-left: 0px;
	margin-bottom: 10px;
}

.well,
.alert-info
 {
	background-image: none !important;
}

/*.prodcomp{
	margin: 0;
	display: inline-block;
	
}

.prodcomp input {
	margin-top:-2px;
	vertical-align: text-bottom;
}

.prodcomp.line{
	margin: 0;
	display: inline-block;
}

.prodcomp.line input {
	margin: 0;
	vertical-align: text-top;
}
*/
.prodcomp a {
	color: #000099;
}

.prodcomp a[href] {
	text-decoration: underline;
}
.propmatrix tbody tr:first-child td
{
	padding-top: 30px;
}

.propmatrix .prodinfo {
	font-weight: normal;
}

.propmatrix .group {
	background-image: url("/images/cat_driehoekbl_sel.png");
    background-position: 7px 10px;
    background-repeat: no-repeat;
    color: #000099;
    font-size: 12px;
    font-weight: bold;
    
}
.propmatrix .group td {
	padding-left: 25px;
}

.propmatrix .head {
	font-size: 11px;
	padding-left: 25px;
}




.propmatrix .value {
	font-size: 11px;
	font-weight: bold;
}

.propmatrix .value.diff {
	color: #3399ff;

	}
	
.propmatrix tr.group td {
	background-color: #eee;
}	
	
#comparelist h3 {
	font-size: 12px;
	font-weight:bold;
	margin-top:5px;
	color: #000099;
}	

#comparelist .list-group-item.prodinfo {
	font-size: 11px;
}

.compare-img {
	background-image: url(/images/n_verwijder_new.gif);
    background-repeat: no-repeat;
    background-position: 0px -30px;
    height: 13px;
    width: 58px;
    border:0px;
    background-color: #fff;
}

.compare-img:hover {
	background-position: 0px 0px;
    
}

/** Volume pricing */

.bestel ul.volume {
	padding-left:0;
	list-style: none;
	font-size: 12px;
}

.bestel ul.volume li span.price {
	width: 100px;
	display: inline-block;
	text-align: right;
	}
	
.bestel ul.volume li span.cnt {
	width: 40px;
	display: inline-block;
	}	
	
.bestel ul.volume li.title {
	font-weight: bold;
	background: url(/images/volume.gif) no-repeat;
	padding-left: 16px;
}

.visible-xs .bestel ul.volume {
	margin-top: 5px;
}

.visible-xs .bestel ul.volume li.title {
	font-weight: normal;
}

#content .ekko-lightbox-nav-overlay a
{
	font-family: "flexslider-icon";
    font-size: 35px;
    color: #3399ff;
	text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

/* adyen styling */

img.paybrand {
	height: 24px;
	vertical-align: text-top;
	margin-left: 10px;
}


.adyen-checkout__label__text
{
	color: #000099!important;
	font-size:14px!important;
}

.adyen-checkout__button {
	background-color: #3399ff!important;
	color: #ffffff !important;
	border-radius: 0px!important;
	margin-right: 5px!important;
	margin-bottom: 5px!important;
}

/* formcontrol 'glow' */
.adyen-checkout__input--focus,
.adyen-checkout__input--focus:hover,
.adyen-checkout__input:active,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus,
.adyen-checkout__input:focus:hover
{
	border-color: #ffa366!important;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px
		rgba(255, 163, 102, 0.6)!important;
	outline: 0 none!important;
}

.adyen-checkout__input {
border: 1px solid #ccc!important;
}

.bluelink-form .form-group {
	clear: both;
	margin-bottom: 15px;
}
.bluelink-form .form-group.form-group-h {
	height: 34px;
}
.bluelink-form .form-group .control-label {
	padding-top: 7px;
}

.alert.alert-covid {
	background-color: #FFA366;
}

.alert.alert-covid h3 {
	margin-top: 0px;
}

.bl-content .alert {
	margin-right: 6px;
}

/* select2 styling */

.select2-dropdown.bl-select2-drop {
	font-size: inherit;
	font-weight: normal;
	color: #555;
}

.select2-dropdown.bl-select2-drop .select2-results__option--highlighted[aria-selected]
{
	background: #000099;
}

.select2-results__option {
	line-height: 1;
}

.visible-xs .prod-info:last-child {
	margin-bottom: 10px;
}

.profile .row {
	margin-left: -5px;
	align-items: center;
}



/* Fix google Chrome */

    
input[type=text]{
	border: 1px solid #ccc;
}

input[type=text]:invalid{
	border: 1px solid #FF6600;
}
input[type=text]:focus {
	border-color: #3399ff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(51, 153, 255, 0.6);
}

.extrainfo {
	margin-left: 20px;
	color: #009;
	font-weight: bolder;
	text-decoration: underline;
	font-size: 11px
}
.extrainfo:hover {
	color: #39f;
	text-decoration: underline!important;
}

/* Filters */

div.filters {
	margin-top: 5px;
}

p.filters,
div.filters p {
	padding-left: 10px;
}


ul.filters {
	padding-left: 10px;
}

ul.filters ul {
	padding-left: 12px;
	padding-top: 5px;
}

ul.filters li {
	list-style-type: none;
	margin-top: 2px;
}

ul.filters > li {
	border-top: 1px solid gray;
	padding-top: 5px;
	padding-bottom: 5px;
}
ul.filters > li > a {
	font-weight: bold;
	color: gray;
	background-repeat: no-repeat;
	padding-left: 14px;
	display: inline-block;
	background-position: 0 5px;
}

ul.filters >li > ul:not(#group-filter):not(#group-serie):not(#group-range) >li:nth-child(n+11):not(.showmore):not(.showless) {
    display:none;
}

/*ul.filters >li > ul .small >li label,
ul.filters.small >li > ul >li label { 
	font-size:small;
	font-weight: normal;
}

ul.filters >li > ul .small > li label,
ul.filters >li > ul > li label { 
	margin-left:24px;
}*/

ul.filters.small >li > ul >li input.checkbox-inline,
ul.filters >li > ul .small >li input.checkbox-inline{
	margin-top:3px;
}

ul.filters >li > ul .small >li input.checkbox-inline,
ul.filters >li > ul >li input.checkbox-inline {
	vertical-align: sub;
	margin-left: -24px;
	float:left;
	
}

ul.filters >li > ul >li input.checkbox-inline {
	margin-top:5px;

	}

ul.filters >li > ul >li label i { 
	color: #39f;
}


ul.filters > li > a[aria-expanded="true"]
{
    background-image: url('/images/cat_driehoekbl_sel.gif');   
    background-position: 0 2px;
  	background-repeat: no-repeat;
}  

ul.filters > li > a[aria-expanded="false"]
{
    background-image: url('/images/cat_driehoekbl.gif');
    background-position: 0 3px;
  background-repeat: no-repeat;
}
	
#mobilefilter.in,
#mobilefilter.collapsing {
    display: block!important;
}

/* Option filters */

div.options > div.level3:nth-child(n+5) {
    /*display:none;*/
}

div.options a.showmore {
	margin-left: 17px;
}

div.card-body a.docmore + p.more {
	display:	none;
}

div.alert a.docmore + p.more,
div.card-body a.docmore + p.more {
	display:	none;
}


/* password eye */

.bl-pass .bi-eye, .bl-pass .bi-eye-slash {
	cursor: pointer;
}

/* relevant options*/
#catopt .level3[data-isrel='false']
{
    display: none;
}
.spinner-border {
	border-radius: 50% !important;
}

.spinner-border-bluelink {
	--bs-spinner-width: 1rem;
	--bs-spinner-height: 1rem;

}






/* portal action items */
.widget-portal .widget-portal-header {
	border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem;
}

.widget-portal .widget-portal-icon {
	font-size: 3em;
	float:left;
	color:  rgba(0,0,0,.15);
}

/* stepper color change */

.bluelink-wrapper .active .bs-stepper-circle {
	background-color: #ff8c40;
}


/* order with items no select */
.container.order .card-body div.products div a {
	pointer-events: none;
}

/* BOM Lines */
.prijslijst tr.bomline td {
    border: none;
    font-size: smaller;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.prijslijst tr.bomline td.prodinfo {
    padding-left: 27px;
}

/* last item */
.prijslijst tr.bomline.last td {
    padding-bottom: 0.75rem;
}

/* BOM Items */
.prijslijst tr.bomitem td {
    padding-bottom: 0.2rem;
}

